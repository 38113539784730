import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const routes = [
  {
    path: '/',
    name: '',
    component: () => import('../views/home.vue'),
    meta: {
      title: '啤酒游戏 - 长鞭效应仿真模拟',
      isRouterAuth: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    meta: {
      title: '啤酒游戏 - 登录',
      isRouterAuth: false
    }
  },
  {
    path: '/param/:role',
    name: 'param',
    component: () => import('../views/param.vue'),
    meta: {
      title: '啤酒游戏 - 设定参数',
      isRouterAuth: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register.vue'),
    meta: {
      title: '啤酒游戏 - 注册新用户',
      isRouterAuth: false
    }
  },
  {
    path: '/rolepage',
    name: 'rolepage',
    component: () => import('../views/rolepage.vue'),
    meta: {
      title: '啤酒游戏 - 选择角色',
      isRouterAuth: true
    }
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: () => import('../views/resetPassword.vue'),
    meta: {
      title: '啤酒游戏 - 重置密码',
      isRouterAuth: false
    }
  },
  {
    path: '/retailer',
    name: 'retailer',
    component: () => import('../views/retailer.vue'),
    meta: {
      title: '啤酒游戏 - 零售商',
      isRouterAuth: true
    }
  },
  {
    path: '/distributor',
    name: 'distributor',
    component: () => import('../views/distributor.vue'),
    meta: {
      title: '啤酒游戏 - 供应商',
      isRouterAuth: true
    }
  },
  {
    path: '/factory',
    name: 'factory',
    component: () => import('../views/factory.vue'),
    meta: {
      title: '啤酒游戏 - 制造商',
      isRouterAuth: true
    }
  },
  {
    path: '/personalcenter',
    name: 'personalcenter',
    component: () => import('../views/personalCenter.vue'),
    meta: {
      title: '啤酒游戏 - 个人中心',
      isRouterAuth: true
    }
  },
  {
    path: '/beginner',
    name: 'beginner',
    component: () => import('../views/beginner.vue'),
    meta: {
      title: '啤酒游戏 - 新手指引',
      isRouterAuth: true
    }
  },
  {
    path: '/end/:id',
    name: 'end',
    component: () => import('../views/end.vue'),
    meta: {
      title: '啤酒游戏 - 结束',
      isRouterAuth: true
    }
  },
  {
    path: '/*',
    redirect: '/'
  }
]
const router = new Router({
  routes
})
router.beforeEach((to, from, next) => {
  const token = 1 // 暂时开通通道
  if (to.meta.isRouterAuth) {
    if (token) {
      window.document.title = to.meta.title
      next()
    } else {
      next('/login')
    }
  } else {
    window.document.title = to.meta.title
    next()
  }
})
export default router