import Vue from 'vue'
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import '@/assets/style/reset.css'
import '../theme/index.css'
import * as echarts from 'echarts'
import App from './App.vue'
import router from './router/index.js'

Vue.use(Element)
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
