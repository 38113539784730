<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  methods: {},
  data () {
    return {
    }
  }
}
</script>

<style lang="less" scoped>
</style>
